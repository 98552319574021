import React, { useEffect, useState  } from 'react';
import { AgentNav } from "./AgentNav";
import api from '../api';
export const SellerListing = () => {
    const [sellerlisting, setSellerListing] = useState([]);
    const [sellercount, setSellerCount] = useState(0); // State for seller count
    const storedAgentId = sessionStorage.getItem('agentId'); 
    useEffect(() => {
        fetchData();
     }, []);

     const fetchData = async () => {
        try {
            const response = await api.get(`/agent/sellerlistinginagent/${storedAgentId}`);
            console.log(response.data.Seller)
               if (response.data.Seller && Array.isArray(response.data.Seller)) {

                  setSellerListing(response.data.Seller);
                  const sellerList = response.data.Seller;
                  setSellerCount(sellerList.length); // Update count
                  // Log the count of items in the array
                  console.log(`Number of sellers: ${sellerList.length}`);
              } else {
                  console.error('Calendar data is not in the expected format:', response.data);
                  // Handle the situation when data is not in the expected format
                  // For example, you can set calendarData to an empty array
                  setSellerListing([]);
                  
              }
         } catch (error) {
             console.error('Error fetching data:', error);
         }
     };
     const toggleActivation = async (Seller_Id) => {
        try {
            await api.post(`/admin/selleractivatedeactivate/${Seller_Id}`);
            fetchData();
        } catch (error) {
            console.error('Error updating data:', error);
        }
    };
  return (
    <section class="dealer-listing">
         <AgentNav />
         <div class="mid-panel mt-3">
            <div class="container">
                <div class="toplisting">
                    <h3 class="main-heading">Seller Listing </h3>
                    <div class="badge badge-primary p-2">Total Sellers:  <div class="count"><strong>{sellercount}</strong></div></div>
               </div>
                <div class="flip-card pt-2">
                  <div class="row">
                  {sellerlisting.map((item) => (
                        <div class="col-lg-4 col-md-6 pt-3">
                            <div class="card card-simple">
                                <div class="card-front text-white badge badge-primary">
                                    <div class="card-body">                              
                                        <h6 class="card-title">{item.Seller_FName} {item.Seller_LName}</h6>
                                        <h6 class="card-title">{item.Seller_City}</h6>  
                                        <h6 class="card-title">{item.Seller_PostalCode}</h6>
                                        <h6 class="card-title">{item.Seller_Phone}</h6>    
                                        <h6 class="card-title">{item.Seller_Email}</h6>
                                        <h6 class="card-title">No Of Cars Posted:{item.vehicle_count}</h6>  
                                        <div className="text-center">
                                        {item.Active_Status === 1 || item.Active_Status === 0 ? (
                                                                                <button
                                                                                    className="btn btn-sm btn-outline-light px-2 py-2 mt-2"
                                                                                    onClick={() => toggleActivation(item.Seller_Id)}
                                                                                >
                                                                                    {item.Active_Status === 1 ? "Deactivate" : "Activate"}
                                                                                </button>
                                                                            ) : null}
                                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                     ))}
                  </div>
               </div>
            </div>
         </div>
      </section>
  )
}
