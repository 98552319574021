import React, { useState, useEffect, useRef } from 'react';
import { AgentNav } from "./AgentNav";
import api from '../api';
import { useNavigate } from 'react-router-dom';
import debounce from 'lodash.debounce'; // Import debounce function
export const  FutureCarAuctions = () => {
const navigate = useNavigate();
const [VehicleListCount, setVehicleCount] = useState(0); // State for vehicle count
const [posts, setPosts] = useState([]);
const [error, setError] = useState(null);
const [loading, setLoading] = useState(true); // Add a loading state
const storedDealerId = sessionStorage.getItem('user_id');
const storedAgentId = sessionStorage.getItem("agentId");
const [searchQuery, setSearchQuery] = useState('');
const [selectedMakes, setSelectedMakes] = useState([]);
const [selectedYear, setSelectedYear] = useState("");
const [isOpen, setIsOpen] = useState(false);
const [makes, setMakes] = useState([]);
const [years, setYears] = useState([]);
const [mileage, setMileage] = useState('');
const [models, setModels] = useState([]); // List of models
const toggleDropdown = () => setIsOpen(!isOpen);
const [selectedModel, setSelectedModel] = useState('');
const [isModelOpen, setIsModelOpen] = useState(false); 
const dropdownRef = useRef(null);
const modelDropdownRef = useRef(null); 
const toggleModelDropdown = () => setIsModelOpen((prev) => !prev);
const [totalPostsCount, setTotalPostsCount] = useState(0);
const [model, setModel] = useState('');
const [year, setYear] = useState('');
const handleMakeCheckboxChange = (makeId) => {
  setSelectedMakes((prev) =>
	prev.includes(makeId)
	  ? prev.filter((id) => id !== makeId)
	  : [...prev, makeId]
  );
};
const handleClickOutside = (event) => {
  if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
	setIsOpen(false);  // Close dropdown if click is outside
  }
  if (modelDropdownRef.current && !modelDropdownRef.current.contains(event.target)) {
	setIsModelOpen(false);  // Close dropdown if click is outside
  }
};

useEffect(() => {
  document.addEventListener('click', handleClickOutside);
  return () => document.removeEventListener('click', handleClickOutside);
}, []);
useEffect(() => {
  // Add event listener to handle clicks outside
  document.addEventListener("mousedown", handleClickOutside);

  // Cleanup event listener when component is unmounted
  return () => {
	document.removeEventListener("mousedown", handleClickOutside);
  };
}, []);

useEffect(() => {
fetchMakeYear();
const fetchPosts = async () => {
try {
const response = await api.get(`/vehiclelisting/${storedAgentId}/Agent/FutureAuctions`);
const { message, Vehicle } = response.data;
// Check if message is true and Vehicle is an array
if (message && Array.isArray(Vehicle)) {
// setVehicleId();
// Set the Vehicle data in state
setVehicleCount(Vehicle.length);  // Count the number of vehicles
setPosts(Vehicle);
setLoading(false); 
} else {
throw new Error('Invalid data format: Vehicle array not found');
}
} catch (error) {
setError(error.message);
setLoading(false); 
}
};
fetchPosts();
}, [navigate, storedDealerId]);
const fetchSerchdata = async () => {
try {
const response = await api.post(`/vehiclesearch/${storedAgentId}/Agent/FutureAuctions`, {
search: searchQuery
});
// Extract the array of vehicles from the "Vehicle" property
const vehicles = response.data.Vehicle;
// Set the state with the array of vehicles
setPosts(vehicles);
} catch (error) {
console.error('Error fetching data:', error);
}
};
const fetchSearchData = debounce(async () => {
    try {
      // Map selectedMake IDs to names
      const selectedMakeNames = makes
        .filter((make) => selectedMakes.includes(make.make_id))
        .map((make) => make.make_name);
  
      // Map selectedModel IDs to names
      const selectedModelNames = Object.keys(models)
        .flatMap((makeName) =>
          models[makeName].filter((model) =>
            selectedModel.includes(model.model_id)
          )
        )
        .map((model) => model.model_name);
  
      console.log("Filters being sent to API:", {
        Year: selectedYear,
        Make: selectedMakeNames.join(','), // Use make names
        Model: selectedModelNames.join(','), // Use model names
        Mileage: mileage,
      });
  
      const response = await api.post(`/vehiclesearch/${storedAgentId}/Agent/FutureAuctions`, {
        Year: selectedYear,
        Make: selectedMakeNames.join(','), // Pass make names
        Model: selectedModelNames.join(','), // Pass model names
        Mileage: mileage,
      });
  
      const vehicles = response.data.Vehicle;
      console.log("Vehicles fetched:", vehicles);
  
      if (vehicles) {
        setPosts(vehicles);
        setTotalPostsCount(response.data.TotalVehicleCount || vehicles.length); // Use TotalVehicleCount or fallback to the length
      } else {
        console.warn("No vehicles found for the selected filters.");
        setPosts([]);
      }
  
    
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }, 1000);
  useEffect(() => {
    fetchSearchData();
  }, [selectedYear, selectedMakes, selectedModel, mileage, storedDealerId]);
  const fetchSearchDataCount = debounce(async () => {
    try {
      // Map selectedMake IDs to names
      const selectedMakeNames = makes
        .filter((make) => selectedMakes.includes(make.make_id))
        .map((make) => make.make_name);
  
      // Map selectedModel IDs to names
      const selectedModelNames = Object.keys(models)
        .flatMap((makeName) =>
          models[makeName].filter((model) =>
            selectedModel.includes(model.model_id)
          )
        )
        .map((model) => model.model_name);
  
      console.log("Filters being sent to API:", {
        Year: selectedYear,
        Make: selectedMakeNames.join(','), // Use make names
        Model: selectedModelNames.join(','), // Use model names
        Mileage: mileage,
      });
  
      const response = await api.post(`/vehiclesearch/${storedAgentId}/Agent/FutureAuctions`, {
        Year: selectedYear,
        Make: selectedMakeNames.join(','), // Pass make names
        Model: selectedModelNames.join(','), // Pass model names
        Mileage: mileage,
      });
  
      const vehicles = response.data.Vehicle;
      console.log("Vehicles fetched:", vehicles);
  
      if (vehicles) {
        setTotalPostsCount(response.data.TotalVehicleCount || vehicles.length); // Use TotalVehicleCount or fallback to the length
      } else {
        console.warn("No vehicles found for the selected filters.");
      }
  
    
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }, 1000);
  useEffect(() => {
    fetchSearchDataCount();
  }, [selectedYear, selectedMakes, selectedModel, mileage, storedDealerId]);
  
   // Handle input changes
   const handleYearChange = (event) => {
     setYear(event.target.value);
     fetchSearchData();
   };
  
   const handleMakeChange = (e) => {
    const selectedOptions = Array.from(e.target.selectedOptions, (option) => parseInt(option.value));
    setSelectedMakes(selectedOptions); // Update selected makes
  };
  
   const handleModelChange = (event) => {
     setModel(event.target.value);
     fetchSearchData();
   };
  
   const handleMileageChange = (event) => {
     setMileage(event.target.value);
     fetchSearchData();
   };
   const fetchMakeYear = debounce(async () => {
    try {
      const response = await api.get(`/get-year-make`);
      console.log(response.data);
  
      // Extract Make and Year from the response
      if (response.data) {
        setMakes(response.data.Make); // Assuming Make is an array of objects
        setYears(response.data.Year); // Assuming Year is an array of integers
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }, 1000);
  
  
  // Fetch Models based on selected makes
  const fetchModels = async () => {
    if (selectedMakes.length === 0) return;
  
    try {
      const response = await api.post('/get-model', {
        makes: selectedMakes.join(','),
      });
  
      if (response.data && response.data.Model) {
        setModels(response.data.Model); // Set models directly
      } else {
        console.error('Invalid models data:', response.data);
      }
    } catch (error) {
      console.error('Error fetching models:', error);
    }
  };
  useEffect(() => {
    fetchModels();
  }, [selectedMakes]);
  
  const handleModelCheckboxChange = (modelId) => {
    setSelectedModel((prev) =>
      prev.includes(modelId)
        ? prev.filter((id) => id !== modelId)
        : [...prev, modelId]
    );
  };
  useEffect(() => {
    console.log('Models state:', models);
  }, [models]);
  const handleClear = () => {
    setSelectedMakes([]);
  };
  const handleClearmodels = () => {
    setSelectedModel([]);
  };
  const handleApply = () => {
    setIsOpen(false);
    setIsModelOpen(false);
  };
   // Get names of selected makes
   const selectedMakeNames = makes
   .filter((make) => selectedMakes.includes(make.make_id))
   .map((make) => make.make_name)
   .join(', ');
  
  // Get names of selected models
  const selectedModelNames = Object.keys(models)
   .flatMap((makeName) =>
     models[makeName].filter((model) =>
       selectedModel.includes(model.model_id)
     )
   )
   .map((model) => model.model_name)
   .join(', ');
  
return (
<section className="car-details">
	<AgentNav />
	<div className="container">
		<div className="view-post-panel mid-table mt-4">
		<div class="toplisting">
			<h3 className="main-heading py-3">Upcoming Cars</h3>
                           </div>
			<div className="car-bid-gallary">
			<form onSubmit={(e) => e.preventDefault()} className="input-group">
    <div className="row upcoming-filter w-100 feature-filter">
      {/* Year Dropdown */}
      <div className="col mb-3 feature-filter-item">
        <div className="field-bx">
          <select
            className="form-control"
            value={selectedYear}
            onChange={(e) => setSelectedYear(e.target.value)}
          > 
            <option value="">Select Year</option>
            {years.map((year) => (
              <option key={year} value={year}>
                {year}
              </option>
            ))}
          </select>
        </div>
      </div>

      {/* Make Dropdown */}
      <div className="col mb-3 feature-filter-item" ref={dropdownRef}>
        <div className="field-bx">
          <div className="dropdown">
            <button
              className="form-control p-3 border"
              type="button"
              onClick={toggleDropdown}
            >
              {selectedMakeNames || "Select Make"}
            </button>
            {isOpen && (
              <div
                className="dropdown-menu show"
                style={{ maxHeight: "300px", overflowY: "auto" }}
              >
                
                {makes.map((make) => (
                  <div key={make.make_id} className="dropdown-item">
                    <label>
                      <input
                        type="checkbox"
                        value={make.make_id}
                        checked={selectedMakes.includes(make.make_id)}
                        onChange={() => handleMakeCheckboxChange(make.make_id)}
                      />
                      {make.make_name}
                    </label>
                  </div>
                ))}
                
                <div className="dropdown-footer d-flex justify-content-between p-2">
                  <button
                    className="btn btn-sm btn-clear"
                    onClick={handleClear}
                  >
                    Clear Filter
                  </button>
                  <button
                    className="btn btn-sm btn-apply"
                    onClick={handleApply}
                  >
                    Apply
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      {/* Model Dropdown */}
      <div className="col mb-3 feature-filter-item" ref={modelDropdownRef}>
        <div className="field-bx">
          <div className="dropdown">
            <button
              className="form-control p-3 border"
              type="button"
              onClick={toggleModelDropdown}
              disabled={selectedMakes.length === 0} // Disable when no makes are selected
            >
              {selectedModelNames || "Select Model"}
            </button>
            {isModelOpen && selectedMakes.length > 0 && (
              <div
                className="dropdown-menu show"
                style={{ maxHeight: "300px", overflowY: "auto" }}
              >
                {Object.keys(models).map((makeName) => (
                  <div key={makeName}>
                    <div className="dropdown-item">
                      <strong>{makeName}</strong>
                    </div>
                    {models[makeName].map((model) => (
                      <div key={model.model_id} className="dropdown-item">
                        <label>
                          <input
                            type="checkbox"
                            value={model.model_id}
                            checked={selectedModel.includes(model.model_id)}
                            onChange={() => handleModelCheckboxChange(model.model_id)}
                          />
                          {model.model_name}
                        </label>
                      </div>
                    ))}
                  </div>
                ))}
                <div className="dropdown-footer d-flex justify-content-between p-2">
                  <button
                    className="btn btn-sm btn-clear"
                    onClick={handleClearmodels}
                  >
                    Clear Filter
                  </button>
                  <button
                    className="btn btn-sm btn-apply"
                    onClick={handleApply}
                  >
                    Apply
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      {/* Odometer Input */}
      <div className="col mb-3 feature-filter-item">
        <div className="field-bx">
          <input
            type="search"
            className="form-control"
            placeholder="Odometer"
            value={mileage}
            onChange={handleMileageChange}
          />
        </div>
      </div>

      {/* Result Button */}
      <div className="col mb-3 feature-filter-item">
        <div className="field-bx-submit">
          <button
            className="btn btn-primary apply-filter-button"
            onClick={fetchSearchData}
          >
            <span className="val">{totalPostsCount}</span>
            <span className="def ml-2"> {totalPostsCount > 1 ? "Results" : "Result"}</span>
          </button>
        </div>
      </div>
    </div>
  </form>

				<div className="carbid-gallery-panel py-4">
					<div className="row">
						{loading ? (
						<p>Loading...</p>
						// Render loading state while fetching data
						) : posts.length === 0 ? (
						<div className="col-md-12">
							<p className="text-center text-danger">No Upcoming Cars</p>
						</div>
						) : (
						posts.map(post => (
						<div key={post.id} className="col-lg-4 col-12">
							<a href={`/car-details/${post.Vehicle_Id}`}>
								<div className="carbid-image-panel">
									<img src={`https://backend.carchaser.ca/uploads/${post.Vehicle_Id}/${post.Exterior_Image}`} alt="Car" />
									<div className="bidpanel-innercont">
										<h6 className="pt-2">{post.Year} {post.Make} {post.Model}  </h6>
										<span><small>Vin #{post.VIN}</small></span>
										<span><small>Live Auction Date & Time: {post.Live_Auction_DateTime}</small></span>
									</div>
								</div>
							</a>
						</div>
						))
						)}
					</div>
				</div>
			</div>
		</div>
	</div>
</section>
);
};