
import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom"; // import useParams
import { useNavigate } from "react-router-dom";
import api from "../api";
export const Midware = () => {
    const navigate = useNavigate();
    const { Vehicle_Id } = useParams(); // Get the ID from the URL slug
    const { Dealer_Id } = useParams(); // Get the ID from the URL slug
   
      const checkVehicleStatus = async (vehicleId) => {
        try {
          const response = await api.get(`/checkvehiclestatus/${vehicleId}`);
          const data = response.data.status;
          console.log(response.data);
          console.log("Vehicle status:", data);
    
          // Navigate based on the returned status
          if (data === "in-between") {
            console.log("Navigating to car details...");
            navigate(`/car-details/${vehicleId}`);
          } else if (data === "future") {
            console.log("Navigating to future car details...");
            navigate(`/future-car-details/${vehicleId}`);
          } else if (data === "past") {
            console.log("Navigating to past car details...");
            navigate(`/car-details-expire/${vehicleId}`);
          }
        } catch (error) {
          console.error("Error checking vehicle status:", error);
        }
      };
      useEffect(() => {
        if (Vehicle_Id) {
          checkVehicleStatus(Vehicle_Id);
        }
      }, [Vehicle_Id]);
      useEffect(() => {
        
        if (Dealer_Id) {
          // Store Dealer_Id in sessionStorage
          sessionStorage.setItem("agentId", Dealer_Id);
          console.log("agentId ID stored in session:", Dealer_Id);
        }
        checkVehicleStatus();
      }, [Dealer_Id]); // Runs when Dealer_Id changes
      
  return (
    <section className="car-details">
        Redirect to dealer Network page..
    </section>
  );
};
